import { Link } from "gatsby"
import React from "react"
import {
  commercialpropertyDetailRentURL,
  commercialpropertyDetailSaleURL,
  propertyDetailRentURL,
  propertyDetailSaleURL,
} from "../../lib/urls"
import "./TeamPropertyCard.scss"

const {
  ImageModule,
} = require("@starberry/gatsby-theme-utils/dist/Modules/ImageModule")
const { site_vars } = require("../../common/site/config")

const TeamPropertyCard = (props) => {
  const imagename = "property.images.results"
  const isSales = props?.search_type === "sales"
  const isCommercial = props?.department === "commercial"

  const basePath =
    isCommercial && isSales
      ? commercialpropertyDetailSaleURL
      : isCommercial
      ? commercialpropertyDetailRentURL
      : isSales
      ? propertyDetailSaleURL
      : propertyDetailRentURL
  const propertyURL = `${basePath}${props?.slug}/${props?.strapi_id}/`
  return (
    <div className="featured-properties-slide">
      <div className="featured-properties-img-zoom img-wrapper">
        <Link to={`${propertyURL}`}>
          <ImageModule
            ImageSrc={props?.img}
            altText=""
            imagetransforms={props?.processedImages}
            renderer="srcSet"
            imagename={imagename}
            strapi_id={props?.strapi_id}
            classNames="img-fluid"
          />
          {/* {props.status && (
            <div className="featured-status secondary-text">{props.status}</div>
          )} */}
        </Link>
      </div>
      <div className="featured-properties-text-wrapper">
        <div className="featured-text">
          <div className="d-flex justify-content-between align-items-start">
            <div>
              <Link to={`${propertyURL}`}>
                <div className="price secondary-text">
                  {/* <span className="price-qualifier secondary-text">
            {props.priceTag}</span> */}
                  {site_vars?.default_currency} {props?.price}
                </div>

                <div className="title">{props?.title}</div>
              </Link>
            </div>
            <div
              data-mapropertyid={props?.strapi_id}
              className="ma-sb-myacc ma-save-property"
            >
              <span className="property-save icon-save" />
              <span className=" property-save icon-saved" />
            </div>
          </div>
          {(isCommercial && props?.floorarea_min) ||
          (props?.bedroom && props?.bathroom) ? (
            <div className="icon-items d-flex">
              {props?.bedroom ? (
                <div className="icon-bk bedroom d-flex">
                  <span className="icon icon-bed" />
                  <span className="icon-text">
                    {props?.bedroom}{" "}
                    <span>{props?.bedroom > 1 ? "Beds" : "Bed"}</span>
                  </span>
                </div>
              ) : null}
              {props?.bathroom ? (
                <div className="icon-bk bathroom d-flex">
                  <span className="icon icon-bath" />
                  <span className="icon-text">
                    {props?.bathroom}{" "}
                    <span>{props?.bathroom > 1 ? "Baths" : "Bath"}</span>
                  </span>
                </div>
              ) : null}
              {props?.floorarea_min ? (
                <div className="icon-bk sqft d-flex">
                  <span className="icon icon-sqft" />
                  <span className="icon-text">
                    {props?.floorarea_min}{" "}
                    {props?.floorarea_type === "squareFeet" ? "sq.ft" : ""}
                  </span>
                </div>
              ) : null}
            </div>
          ) : null}
          {props?.address && (
            <div className="address">
              <i className="location icon" /> {props?.address}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default TeamPropertyCard
