import loadable from "@loadable/component"
import _ from "lodash"
import React from "react"
import ScrollAnimation from "react-animate-on-scroll"
import { Col, Container, Row } from "react-bootstrap"
import "slick-carousel/slick/slick-theme.css"
import "slick-carousel/slick/slick.css"
import { sliderSettings } from "../SliderSettings/SliderSettings"
import TeamPropertyCard from "../TeamPropertyCard/TeamPropertyCard"
import "./assets/styles/_index.scss"

const Slider = loadable(() => import("react-slick"))

const TeamProperties = ({ teampropertyData }) => {
  const SliderTab = ({ items }) => {
    if (_.isEmpty(items)) return null

    return (
      <Slider className="team-properties-slider" {...sliderSettings}>
        {items?.map((item, i) => {
          let details_path = "/property-for-sale"
          if (item?.node?.search_type == "lettings") {
            details_path = "/property-for-rent"
          }

          let processedImages = JSON.stringify({})
          if (item?.node?.imagetransforms?.images_Transforms) {
            processedImages = item?.node?.imagetransforms?.images_Transforms
          }

          const image_all = item?.node?.images?.strapi_json_value[0]

          return (
            <ScrollAnimation
              animateIn="animate__slideInUp"
              delay={i * 100}
              animateOnce
              offset={10}
            >
              <TeamPropertyCard
                details_path={details_path}
                {...item?.node}
                img={image_all}
                processedImages={processedImages}
                status="Under Offer"
                bedroom={item.node?.bedroom}
                bathroom={item.node?.bathroom}
                department={item.node?.department}
                floorarea_min={item.node?.floorarea_min}
                floorarea_type={item.node?.floorarea_type}
                address={item.node?.display_address}
                priceTag={item.node?.price_qualifier}
                price={item?.node?.price?.toLocaleString()}
                title={item.node?.title}
                crm_id={item.node?.crm_id}
                strapi_id={item?.node?.strapi_id}
              />
            </ScrollAnimation>
          )
        })}
      </Slider>
    )
  }

  return (
    <>
      {teampropertyData?.length > 0 && (
        <section className="team-properties-wrapper section-p">
          <Container>
            <Row>
              <Col>
                <div className="relative">
                  <div className="team-heading-wrapper">
                    <h2>My Properties</h2>
                  </div>
                  <div className="team-properties-tab-section">
                    {teampropertyData?.length > 0 && (
                      <SliderTab items={teampropertyData} />
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      )}
    </>
  )
}

export default TeamProperties
