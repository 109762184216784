import loadable from "@loadable/component"
import { graphql } from "gatsby"
import React from "react"
import { PageLinks } from "../common/site/page-static-links"
import TeamProperties from "../components/TeamProperties/TeamProperties"
import Layout from "../components/layout"
import Seo from "../components/seo"

const Enquire = loadable(() => import("../components/Enquire/Enquire"))
const BreadcrumbModule = loadable(() =>
  import("../components/BreadcrumbModule/BreadcrumbModule")
)
const TeamDetailDesc = loadable(() =>
  import("../components/TeamDetailDesc/TeamDetailDesc")
)

const TeamDetail = ({ data }, props) => {
  const PageData = data?.strapiTeam
  const teamproperty = data?.allStrapiProperty?.edges?.slice(0,3)

  let breadcrumData

  if (PageLinks?.team_parent_label) {
    breadcrumData = {
      parentname: PageLinks.team_parent_label,
      parent: PageLinks.team_parent,
      subparentname: PageLinks.team_label,
      subparent: PageLinks.team,
      pagename: PageData.title,
    }
  } else {
    breadcrumData = {
      parentname: PageLinks.team_label,
      parent: PageLinks.team,
      pagename: PageData.title,
    }
  }

  return (
    <Layout dark popularSearch="Generic pages" footerContact="footercontact">
      <div className="layout-padding-top">
        <div className="cover-bg">
          <BreadcrumbModule
            subparentlabel={PageLinks?.teamlabel}
            subparentlink={PageLinks?.team}
            title={PageData.name}
            parentlabel={PageLinks?.whybatifylabel}
            parentlink={PageLinks?.whybatify}
          />

          <TeamDetailDesc {...PageData} />
          <TeamProperties teampropertyData={teamproperty}/>
        </div>
      </div>

      <div className="d-xl-none">
        <Enquire
          email={PageData.email}
          name={PageData.name}
          phone={PageData.phone}
          whatsapp={PageData.whatsapp}
          cta_1_label="Email Us"
          cta_2_label="Call"
        />
      </div>
    </Layout>
  )
}

export const Head = ({ data }) => {
  const PageData = data?.strapiTeam
  return (
    <Seo
      title={`${PageData.name} ${
        PageData.designation ? ` | ${PageData.designation}` : ""
      }`}
      description={`Get to know about ${PageData.name} here. Contact one of our estate agents for assistance in finding the right property for you.`}
    />
  )
}

export default TeamDetail

export const query = graphql`
  query ($page_id: Int, $negotiator_id: String) {
    strapiTeam(strapi_id: { eq: $page_id }) {
      ...TeamFragment
      content {
        data {
          content
        }
      }
    }
    allStrapiProperty(filter: {negotiator_id: {eq: $negotiator_id}}) {
      edges {
        node {
          area
          crm_id
          negotiator_id
          search_type
          slug
          strapi_id
          title
          price
          department
          price_qualifier
          publish
          display_address
          images {
            strapi_json_value {
              url
            }
          }
          imagetransforms {
            images_Transforms
          }
          bathroom
          bedroom
          floorarea_min
          floorarea_type
        }
      }
    }
  }
`
